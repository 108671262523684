import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import HeaderTypography from "../components/v2/Typography/HeaderTypography";
import ParagraphTypography from "../components/v2/Typography/ParagraphTypography";
import homeBannerBg from "../images/v2/integration/home-banner-bg.svg";
import marketingCampain from "../images/v2/integration/campaign-icon.svg";
import UnifyMessagingPlatforms from "../images/v2/integration/Unify-messaging-icon.svg";
import integrationWhatsaap from "../images/v2/integration/integration-whatsaap.svg";
import integrationConferencing from "../images/v2/integration/integration-conferencing.svg";
import conferencingIcon from "../images/v2/integration/conferencing-icon.svg";
import integrationLeadCaptureImg from "../images/v2/integration/integration-lead-capture.svg";
import softwareIntegration from "../images/v2/integration/integration-icon.svg";
import marketingCampaignFeatureImg from "../images/v2/integration/marketing-campaign.svg";
import softwareIntegrationFeatureImg from "../images/v2/integration/software-integration.svg";
import emailProvider from "../images/v2/integration/email-icon.svg";
import workWithEmailFeatureImg from "../images/v2/integration/work-with-email-bg.svg";
import calendarIntegration from "../images/v2/integration/calendar-integration.svg";
import calendarIcon from "../images/v2/integration/calendar-icon.svg";
import manageDocuments from "../images/v2/integration/document-icon.svg";
import manageDocumentsImg from "../images/v2/integration/customer-documents.svg";
import buildContacts from "../images/v2/integration/contact-icon.svg";
import buildContactFeatureImg from "../images/v2/integration/build-contact-img.svg";
import integrationsFeaturesBg from "../images/v2/integration/integration-features-bg.svg";
import integrationFeatureEngage from "../images/v2/integration/engage.svg";
import integrationFeatureNuture from "../images/v2/integration/nuture.svg";
import integrationFeatureConversion from "../images/v2/integration/conversion.svg";
import conferenceBGImg from "../images/v2/integration/coferencingbg.svg";
import captureLeadBGImg from "../images/v2/integration/capture-leadbg.svg";
import integrationFeatureGrow from "../images/v2/integration/grow.svg";
import lightBlueBgImg from "../images/v2/light-blue-bg.svg";
import favToolsBgImg from "../images/v2/integration/fav-tools-bg.svg";
import testimonialBgImg from "../images/v2/testimonial-bg.svg";
import requestDemoBGImg from "../images/v2/request-demo-bg.svg";
import WhiteButton from "../components/v2/WhightButton";
import cloudIntegrations from "../images/v2/integration/integrations-cloud.svg";
import AccordionHeader from "../components/v2/AccordionHeader";
import useWidth from "../hooks/useWidth";
import Hidden from "@material-ui/core/Hidden";
import SEO from "../components/SEO";
import { Link } from "gatsby";
import SingleTestimonial from "../components/v2/HomeSlider/SingleTestimonial";
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {},
  homeBanner: {
    background: `url(${homeBannerBg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    [theme.breakpoints.down("md")]: {
      background: "#e7f2fe",
    },
  },
  expendButton: {
    backgroundColor: "#eaf0f6",
    "&:hover": {
      background: "#a1dae2",
    },
  },
  integrationsFeatures: {
    background: `url(${integrationsFeaturesBg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  integrationFeatureItem: {
    border: "1px solid",
    background: "#fff",
    borderRadius: theme.shape.borderRadius * 2.5,
    borderColor: "#e2f0ff",
    padding: theme.spacing(3),
    minHeight: 278,
    maxHeight: "100%",
    boxShadow: "10px 20px 20px 0 rgba(0, 0, 0, 0.1)",
    "&:hover": {
      background: "#f2f9f9",
      borderColor: "#f2f9f9",
    },
  },
  lightBlueBg: {
    background: `url(${lightBlueBgImg}) center bottom`,
    backgroundSize: "cover",
  },
  favToolsBg: {
    background: `url(${favToolsBgImg}) center bottom`,
    backgroundSize: "cover",
  },
  testimonialCustomerImg: {
    maxWidth: "90%",
    width: "290px",
    borderRadius: "10px",
    boxShadow: "0 15px 50px 0 rgba(0, 0, 0, 0.16)",
    marginTop: "2.65rem",
    marginLeft: "3rem",
  },
  testimonialBg: {
    background: `url(${testimonialBgImg}) no-repeat`,
    backgroundSize: "195px 223px",
  },
  requestDemoRoot: {
    background: `url(${requestDemoBGImg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  videoConference: {
    background: `url(${conferenceBGImg})`,
  },
  Conference: {
    background: `url(${captureLeadBGImg})`,
  },
  workWithEmailFeature: {
    background: `url(${workWithEmailFeatureImg}) center bottom`,
    backgroundSize: "cover",
  },
  img: {
    maxWidth: "100%",
    height: "auto",
  },
  imageBigscreen: {
    textAlign: "right",
    [theme.breakpoints.up("xl")]: {
      textAlign: "left",
    },
  },
  imageBigscreenRight: {
    [theme.breakpoints.up("xl")]: {
      textAlign: "right",
    },
  },
}));

const client = {
  image: (
    <StaticImage
      alt="Pepper Cloud + SiS Distribution (Thailand) PCL"
      layout="constrained"
      src="../images/v2/testimonials-images/pantana-na-takuathung-peppercloud-customer.png"
    />
  ),
  name: "Pantana Na Takuathung",
  position: "General Manager",
  company: "SiS Distribution (Thailand) Public Co., Ltd",
  content: (
    <React.Fragment>
      Pepper Cloud is an excellent all-in-one CRM tool to run multiple
      businesses across multiple domains. Through multiple sales pipelines, we
      track the business opportunities and the conversations between our team
      and our customers. Furthermore, this CRM software boosts the productivity
      of our team by helping us focus on core processes.
    </React.Fragment>
  ),
};

const Integration = () => {
  const width = useWidth();
  const classes = useStyles();
  const [activeMarketing, setActiveMarketing] = React.useState(0);
  const [activeEmail, setActiveEmail] = React.useState([true, true, true]);
  const [activeCalendar, setActiveCalendar] = React.useState(0);
  const [activeDocuments, setActiveDocuments] = React.useState(0);
  const [activeContact, setActiveContact] = React.useState([
    true,
    true,
    true,
    true,
  ]);
  const unifiedMessageChannel = [
    {
      title: "Omnichannel for communication",
      description:
        "Connect as many messaging Channels as you need and communicate with your prospects from a single platform, Pepper Cloud CRM.",
    },
    {
      title: "Holistic view of conversations",
      description:
        "Get an overview of your prospects and their conversations with Channel integration. Understand their intent and win deals faster.",
    },
    {
      title: "Automatic Lead capture",
      description:
        "Never miss a Lead with multi Channel integration. Capture Leads in seconds through Facebook messenger and WhatsApp and move them down the sales funnel.",
    },
  ];

  const marketing = [
    {
      title: "Customer segmentation",
      description:
        "Build your customer segments based on their location, interests, purchase history, and much more. Deliver the emails that matter to them.",
    },
    {
      title: "Personalised campaigns",
      description:
        "Convert leads to opportunities faster by adding a personal touch to your marketing campaigns. Make your messages feel like one-to-one conversations and not unwarranted one-sided conversations.",
    },
    {
      title: "Insights",
      description: (
        <>
          Have conversations that matter. Access marketing campaign analytics
          with the{" "}
          <a href="https://peppercloud.com/sales-dashboard">
            data visualisation dashboard
          </a>{" "}
          and get recommendations on your customers’ preferences. Check how many
          emails got delivered, who opened, clicked, unsubscribed, etc., to
          design better campaigns as you progress.
        </>
      ),
    },
  ];

  const videoConferencingChannel = [
    {
      title: "Schedule and manage video calls",
      description:
        "Organise Zoom meetings without leaving Pepper Cloud CRM. Schedule the meeting, create the Zoom link, and update the meeting details on the go.",
    },
    {
      title: "Access Zoom calls in activities",
      description:
        "Update your activity list automatically by creating and managing your Zoom meetings right from Pepper Cloud CRM.",
    },
    {
      title: "Join calls with just one click",
      description:
        "Eliminate app switching. Join the Zoom calls from Pepper Cloud CRM with just a click.",
    },
  ];

  const captureLeadByChannel = [
    {
      title: "Bring leads into CRM with no extra efforts",
      description:
        "Automatically capture leads from different channels such as Web forms, WhatsApp and Facebook and keep them accessible to your entire team, within CRM. Zapier CRM integration empowers you to capture leads from LinkedIn and Shopify.",
    },
    {
      title: "Convert leads into contacts in no time",
      description:
        "Be in control of all your leads. Assign them to the right team member and convert leads into won deals with the top sales CRM.",
    },
    {
      title: "Track the lead sources with UTM tracking",
      description:
        "Trace the sources and the campaigns from where the leads are generating. Analyse and keep up the marketing efforts that are resulting in positive outcomes",
    },
  ];

  const email = [
    {
      title: "Easy access",
      description: (
        <>
          Integrate your inbox with the best CRM for Office 365 and Gmail to
          access your sales conversations from anywhere. With the easy email
          integration feature of Pepper Cloud CRM, send emails directly from the
          CRM system and auto-attach the reply to your business records.
        </>
      ),
    },
    {
      title: "Adding contacts",
      description:
        "Making headway in your conversation with your prospect? Simply click on the prospect name and add it as a Contact or a Lead in the CRM and engage directly through your CRM software.",
    },
    {
      title: "Flexibility to choose",
      description:
        "While sending out emails from your connected Gmail or Office 365 account, choose which emails you want to bring into the CRM. Ignore all your personal emails while you add your business emails to the CRM.",
    },
  ];

  const calendar = [
    {
      title: "One-way or two-way sync",
      description:
        "Enable one-way sync to access your Google or Office 365 calendar through Pepper Cloud CRM. Or enable two-way sync to access Pepper Cloud records from within your external calendar provider.",
    },
    {
      title: "Event scheduler",
      description:
        "With the event scheduler of Pepper Cloud, ensure you plan your day and schedule meetings and events accordingly so that you are never overbooked.",
    },
    {
      title: "Visual overview",
      description:
        "View your day-to-day activities as a to-do list and be reminded of scheduled meetings and events with notifications.",
    },
  ];

  const documents = [
    {
      title: "Document format",
      description:
        "Pepper Cloud CRM supports the storage of various documents in different formats. Keep your text files, photos, videos, pdf, and all other documents that your business needs, easily accessible.",
    },
    {
      title: "Team association",
      description:
        "The seamless association feature of Pepper Cloud CRM, allows the team to collaborate with ease and stay updated with the relevant information.",
    },
    {
      title: "Reduce sales downtime",
      description:
        "Reduce sales downtime and maximise your sales reps' time by letting them have all the right information accessible within the business opportunity itself. Save the documents associated with business opportunities in one place.",
    },
  ];

  const contact = [
    {
      title: "Automatic contact sync",
      description:
        "Add your contacts from Google or Office 365 to your CRM account as leads or contacts. With Pepper Cloud, not only the basic customer details are synced, but also the custom fields you wish to have such as addresses, organisation, and birthdays.",
    },
    {
      title: "Avoid contact duplication",
      description:
        "Create a unique set of contact data. With the contact integration of Pepper Cloud CRM, you can be assured that your data is always updated and synced without any errors and duplication. If any duplicate records are found, the existing contact gets updated automatically with the new information.",
    },
    {
      title: "Tags and groups",
      description:
        "Be selective while importing the contact list. Using tags and groups of Google contacts and Office 365 contacts, you can experience full control over the contact data that needs to be synced with your CRM software.",
    },
    {
      title: "WhatsApp integration",
      description:
        "Send WhatsApp messages to your contacts, capture messages from inbound leads, and record them in your CRM account. The CRM with WhatsApp integration, allows you to sync your business WhatsApp with Pepper Cloud CRM and manage conversations effectively.",
    },
  ];

  const integration = [
    {
      title: "Manage quotations",
      description:
        "Generate quotations right from your CRM account with utmost convenience. Send them to your contacts and monitor the status on the go.",
    },
    {
      title: "Eliminate data-entry errors",
      description:
        "Avoid unreconciled data entries and data redundancies by auto-populating the data.",
    },
    {
      title: "Improve workflows",
      description: (
        <>
          Sync data automatically and get an overview of your accounts through
          your CRM account and the accounting software.
        </>
      ),
    },
  ];

  return (
    <React.Fragment>
      <SEO
        canonical="/integration"
        description="With powerful CRM system integrations, integrate Gmail, Outlook. Mailchimp, Google drive, and other apps and boost your sales growth multifold"
        keywords="CRM system integration,CRM integration,crm integration tools,crm software integration,crm api integration,what does crm integration mean,what is crm integration"
        ogData={{
          "og:image": [
            "/meta/og/1X1/integrations.png",
            "/meta/og/1024X512/integrations.png",
            "/meta/og/1200X630/integrations.png",
          ],
        }}
        pathname="/integration"
        title="CRM system Integrations | Pepper Cloud CRM"
      />
      <div className={classes.root}>
        <Box className={classes.homeBanner}>
          <Container>
            <Box display="flex" justifyContent="center">
              <Box
                maxWidth={858}
                py={{ sm: 8, xs: 4 }[width] || 16}
                textAlign="center"
              >
                <HeaderTypography component="h1" mb={4}>
                  Accelerate your sales with powerful CRM system integrations
                </HeaderTypography>
                <ParagraphTypography component="p" fontSize={18} mb={6}>
                  With Pepper Cloud CRM integrations, unite all your teams
                  around your prospects. Integrate your favourite sales-boosting
                  apps with your CRM tool to empower your sales team,
                  personalise conversations, shorten the sales cycle, and grow
                  your business.
                </ParagraphTypography>
                <Button
                  className={classes.bookNowButton}
                  color="secondary"
                  component={Link}
                  size="large"
                  to="/contact"
                  variant="contained"
                >
                  Book a Demo
                </Button>
              </Box>
            </Box>
          </Container>
        </Box>
        <Box className={classes.favToolsBg} py={4}>
          <Container>
            <Box display="flex" justifyContent="center">
              <Box maxWidth={932} pb={3} textAlign="center">
                <HeaderTypography
                  component="h3"
                  fontSize={40}
                  fontWeight={700}
                  lineHeight={1.25}
                  mb={5}
                >
                  Team up with your favourite tools
                </HeaderTypography>
                <ParagraphTypography component="p" fontSize={18}>
                  Be ready to effectively engage with your prospects, generate
                  more leads, and win more opportunities. With easy{" "}
                  <a
                    href={
                      "https://blog.peppercloud.com/crm-integrations-everything-you-need-to-know/#"
                    }
                  >
                    {" "}
                    CRM system integrations
                  </a>
                  , your favourite Google apps, Mailchimp, and other
                  sales-boosting tools are just a few clicks away.
                </ParagraphTypography>
                <br />
                <Button
                  className={classes.bookNowButton}
                  color="secondary"
                  component={Link}
                  size="large"
                  to="/contact"
                  variant="contained"
                >
                  Show Me How
                </Button>
              </Box>
            </Box>
          </Container>
        </Box>
        <Box pt={4}>
          <Grid
            alignItems="center"
            container
            direction={{ sm: "column-reverse", xs: "column-reverse" }[width]}
            justify="center"
          >
            <Grid item md={5} sm={12} className={classes.imageBigscreenRight}>
              <img
                alt="CRM with WhatsApp & other social media integration"
                className={classes.img}
                src={integrationWhatsaap}
              />
            </Grid>
            <Grid item md={1} sm={12}></Grid>
            <Grid item md={6} sm={12}>
              <Box
                display="flex"
                justifyContent={{ sm: "center", md: "flex-start" }}
              >
                <Box maxWidth={620} p={1}>
                  <AccordionHeader
                    alt="Unify Messaging"
                    description="Connect with people through any social messaging Channels. Set up your team for success by integrating the CRM tool with your Facebook messenger and WhatsApp business accounts."
                    header="h3"
                    img={UnifyMessagingPlatforms}
                    title="Unify messaging platforms with channel integration"
                  />
                  <Box mb={2} mt={3}>
                    <Divider light />
                  </Box>
                  {unifiedMessageChannel.map((each, index) => (
                    <div key={each.title}>
                      <Box my={1} p={2}>
                        <Box
                          alignItems="center"
                          display="flex"
                          justifyContent="space-between"
                          onClick={() =>
                            setActiveCalendar(
                              index === activeCalendar ? -1 : index
                            )
                          }
                        >
                          <Box
                            component="h4"
                            fontSize="h6.fontSize"
                            fontWeight="600"
                            m={0}
                          >
                            {each.title}
                          </Box>
                          <IconButton
                            aria-label="Expand or Collapse"
                            className={classes.expendButton}
                            color="default"
                          >
                            {index !== activeCalendar ? (
                              <AddIcon fontSize="small" />
                            ) : (
                              <RemoveIcon fontSize="small" />
                            )}
                          </IconButton>
                        </Box>
                        <Collapse in={activeCalendar === index}>
                          <ParagraphTypography fontSize={16} mr={6} mt={2}>
                            {each.description}
                          </ParagraphTypography>
                        </Collapse>
                      </Box>
                      <Divider light />
                    </div>
                  ))}
                  <Box py={8} textAlign="center">
                    <Button
                      className={classes.bookNowButton}
                      color="secondary"
                      component={Link}
                      size="large"
                      to="/multichannel-integration-for-sales-marketing"
                      variant="contained"
                    >
                      {/* eslint-disable-next-line react/no-unescaped-entities */}
                      Read More
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Hidden smDown>
            <Box mt={8}>
              <Divider light />
            </Box>
          </Hidden>
        </Box>
        <Box pt={4}>
          <Grid
            alignItems="center"
            container
            direction={{ sm: "column", xs: "column" }[width]}
            justify="center"
          >
            <Grid item md={6} sm={12}>
              <Box
                display="flex"
                justifyContent={{ sm: "center", md: "flex-end" }}
              >
                <Box maxWidth={620} p={1}>
                  <AccordionHeader
                    alt="Sales outreach"
                    description="Take your sales a step forward by integrating the CRM with marketing tools such as Mailchimp. Segment your customers, trigger your emails and send the right emails to the right people at the right time."
                    header="h3"
                    img={marketingCampain}
                    title="Improve sales outreach with marketing campaigns"
                  />
                  <Box mb={2} mt={3}>
                    <Divider light />
                  </Box>
                  {marketing.map((each, index) => (
                    <div key={each.title}>
                      <Box my={1} p={2}>
                        <Box
                          alignItems="center"
                          display="flex"
                          justifyContent="space-between"
                          onClick={() =>
                            setActiveCalendar(
                              index === activeCalendar ? -1 : index
                            )
                          }
                        >
                          <Box
                            component="h4"
                            fontSize="h6.fontSize"
                            fontWeight="600"
                            m={0}
                          >
                            {each.title}
                          </Box>
                          <IconButton
                            aria-label="Expand or Collapse"
                            className={classes.expendButton}
                            color="default"
                          >
                            {index !== activeCalendar ? (
                              <AddIcon fontSize="small" />
                            ) : (
                              <RemoveIcon fontSize="small" />
                            )}
                          </IconButton>
                        </Box>
                        <Collapse in={activeCalendar === index}>
                          <ParagraphTypography fontSize={16} mr={6} mt={2}>
                            {each.description}
                          </ParagraphTypography>
                        </Collapse>
                      </Box>
                      <Divider light />
                    </div>
                  ))}
                </Box>
              </Box>
            </Grid>
            <Grid item md={1} sm={12}></Grid>
            <Grid item md={5} sm={12}>
              <img
                alt="best crm for email marketing with Mailchimp & MailerLite integration"
                className={classes.img}
                src={marketingCampaignFeatureImg}
              />
            </Grid>
          </Grid>
          <Hidden smDown>
            <Box mt={8}>
              <Divider light />
            </Box>
          </Hidden>
        </Box>
        <Box className={classes.workWithEmailFeature}>
          <Box component={Container} textAlign="center">
            <AccordionHeader
              alignItems="center"
              alt="CRM Gmail integration"
              description={
                <Box display="flex" justifyContent="center">
                  <Box maxWidth={800}>
                    Get the complete context of conversations with CRM Gmail
                    integration or by syncing Outlook with your CRM account.
                    With easy native integrations of the{" "}
                    <a href="https://peppercloud.com/">best sales CRM</a> ,
                    never struggle to find the right emails nor lose important
                    emails while switching between the apps.
                  </Box>
                </Box>
              }
              display="flex"
              flexDirection="column"
              header="h3"
              img={emailProvider}
              title="Connect your email client and have contextual conversations"
            />
          </Box>
          <br />
          <br />
          <Box component={Container} textAlign="center">
            <Box minHeight="260px">
              <Grid container spacing={4}>
                {email.map((each, index) => (
                  <Grid item key={each.title} md={4} sm={12}>
                    <Divider light />
                    <Box m={0} pb={0} pt={2} px={2}>
                      <Box
                        alignItems="center"
                        display="flex"
                        justifyContent="space-between"
                        onClick={() => {
                          activeEmail[index] = !activeEmail[index];
                          setActiveEmail([...activeEmail]);
                        }}
                      >
                        <Box
                          color="text.secondary"
                          component="h4"
                          fontSize="h6.fontSize"
                          fontWeight="600"
                          m={0}
                        >
                          {each.title}
                        </Box>
                        <IconButton
                          aria-label="Expand or Collapse"
                          className={classes.expendButton}
                          color="default"
                        >
                          {!activeEmail[index] ? (
                            <AddIcon fontSize="small" />
                          ) : (
                            <RemoveIcon fontSize="small" />
                          )}
                        </IconButton>
                      </Box>
                      <Collapse in={activeEmail[index]}>
                        <ParagraphTypography
                          fontSize={16}
                          mb={0}
                          mr={6}
                          mt={2}
                          textAlign="initial"
                        >
                          {each.description}
                        </ParagraphTypography>
                      </Collapse>
                    </Box>
                    <Hidden smDown>
                      <Divider light />
                    </Hidden>
                  </Grid>
                ))}
              </Grid>
            </Box>
            <Box>
              <img
                alt="crm for gmail, outlook & office 365 integration"
                className={classes.img}
                src={cloudIntegrations}
              />
            </Box>
            <br />
          </Box>
          <br />
        </Box>
        <Box pt={4}>
          <Grid
            alignItems="center"
            container
            direction={{ sm: "column-reverse", xs: "column-reverse" }[width]}
            justify="center"
          >
            <Grid item md={5} sm={12} className={classes.imageBigscreenRight}>
              <img
                alt="Google calendar integration, outlook CRM integration, CRM system integration with calendar"
                className={classes.img}
                src={calendarIntegration}
              />
            </Grid>
            <Grid item md={1} sm={12}></Grid>
            <Grid item md={6} sm={12}>
              <Box
                display="flex"
                justifyContent={{ sm: "center", md: "flex-start" }}
              >
                <Box maxWidth={620} p={1}>
                  <AccordionHeader
                    alt="Calendar integration"
                    description="Harmonise your schedule by integrating your CRM with the calendar of your choice. Bring all your meetings, events, and appointments under one platform with Google calendar integration or Office 365 calendar integration."
                    header="h3"
                    img={calendarIcon}
                    title="Never miss an appointment with calendar integration"
                  />
                  <Box mb={2} mt={3}>
                    <Divider light />
                  </Box>
                  {calendar.map((each, index) => (
                    <div key={each.title}>
                      <Box my={1} p={2}>
                        <Box
                          alignItems="center"
                          display="flex"
                          justifyContent="space-between"
                          onClick={() =>
                            setActiveCalendar(
                              index === activeCalendar ? -1 : index
                            )
                          }
                        >
                          <Box
                            component="h4"
                            fontSize="h6.fontSize"
                            fontWeight="600"
                            m={0}
                          >
                            {each.title}
                          </Box>
                          <IconButton
                            aria-label="Expand or Collapse"
                            className={classes.expendButton}
                            color="default"
                          >
                            {index !== activeCalendar ? (
                              <AddIcon fontSize="small" />
                            ) : (
                              <RemoveIcon fontSize="small" />
                            )}
                          </IconButton>
                        </Box>
                        <Collapse in={activeCalendar === index}>
                          <ParagraphTypography fontSize={16} mr={6} mt={2}>
                            {each.description}
                          </ParagraphTypography>
                        </Collapse>
                      </Box>
                      <Divider light />
                    </div>
                  ))}
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Hidden smDown>
            <Box my={8}>
              <Divider light />
            </Box>
          </Hidden>
        </Box>
        <Box alignItems="center" display="flex" justifyContent="center">
          <Box maxWidth={900} pb={2} textAlign="center">
            <AccordionHeader
              alignItems="center"
              alt="Sales documents"
              description="Keep all your sales documents handy. Avoid the inconvenience of not finding the right quotes, proposals or contracts when you are working on an important business opportunity. Attach and store the relevant documents with your opportunities and access them anytime, anywhere."
              display="flex"
              flexDirection="column"
              header="h3"
              img={manageDocuments}
              title="Seamlessly manage your documents"
            />
          </Box>
        </Box>
        <Grid alignItems="center" container justify="center">
          <Grid item md={6} sm={12}>
            <Box
              display="flex"
              justifyContent={{ sm: "center", md: "flex-end" }}
            >
              <Box maxWidth={620} p={1}>
                <Box my={2}>
                  <Divider light />
                </Box>
                {documents.map((each, index) => (
                  <div key={each.title}>
                    <Box my={1} p={1}>
                      <Box
                        alignItems="center"
                        display="flex"
                        justifyContent="space-between"
                        onClick={() =>
                          setActiveDocuments(
                            index === activeDocuments ? -1 : index
                          )
                        }
                      >
                        <Box
                          color="text.secondary"
                          component="h4"
                          fontSize="h6.fontSize"
                          fontWeight="600"
                          m={0}
                        >
                          {each.title}
                        </Box>
                        <IconButton
                          aria-label="Expand or Collapse"
                          className={classes.expendButton}
                          color="default"
                        >
                          {index !== activeDocuments ? (
                            <AddIcon fontSize="small" />
                          ) : (
                            <RemoveIcon fontSize="small" />
                          )}
                        </IconButton>
                      </Box>
                      <Collapse in={activeDocuments === index}>
                        <ParagraphTypography fontSize={16} mr={6} mt={2}>
                          {each.description}
                        </ParagraphTypography>
                      </Collapse>
                    </Box>
                    <Divider light />
                  </div>
                ))}
              </Box>
            </Box>
          </Grid>
          <Grid item md={6} sm={12}>
            <Box className={classes.imageBigscreen}>
              <img
                alt="Google drive CRM integration. A CRM system integrated with Google docs, Google sheets, PDF"
                className={classes.img}
                src={manageDocumentsImg}
              />
            </Box>
          </Grid>
        </Grid>
        <Box my={{ sm: 4, xs: 4, md: 8 }}>
          <Divider light />
        </Box>
        <Box component={Container} textAlign="center">
          <AccordionHeader
            alignItems="center"
            alt="Google and Outlook contacts"
            description={
              <Box display="flex" justifyContent="center">
                <Box maxWidth={800}>
                  Manage your contacts at one central platform. Access the
                  contacts on the go by integrating Google and Office 365
                  contact with CRM software.
                </Box>
              </Box>
            }
            display="flex"
            flexDirection="column"
            header="h3"
            img={buildContacts}
            mb={4}
            title="Integrate your contacts and automate contact sync with fewer efforts"
          />
        </Box>
        <Box className={classes.favToolsBg}>
          <Box component={Container} textAlign="center">
            <Box minHeight="280px">
              <Grid container spacing={4}>
                {contact.map((each, index) => (
                  <Grid item key={each.title} md={6} sm={12}>
                    <Divider light />
                    <Box mb={0} mt={1} pb={0} px={1}>
                      <Box
                        alignItems="center"
                        display="flex"
                        justifyContent="space-between"
                        onClick={() => {
                          activeContact[index] = !activeContact[index];
                          setActiveContact([...activeContact]);
                        }}
                      >
                        <Box
                          color="text.secondary"
                          component="h4"
                          fontSize="h6.fontSize"
                          fontWeight="600"
                          m={0}
                        >
                          {each.title}
                        </Box>
                        <IconButton
                          aria-label="Expand or Collapse"
                          className={classes.expendButton}
                          color="default"
                        >
                          {!activeContact[index] ? (
                            <AddIcon fontSize="small" />
                          ) : (
                            <RemoveIcon fontSize="small" />
                          )}
                        </IconButton>
                      </Box>
                      <Collapse in={activeContact[index]}>
                        <ParagraphTypography
                          fontSize={16}
                          minHeight={{ sm: 0, xs: 0, md: 140 }}
                          mr={6}
                          mt={2}
                          textAlign="initial"
                        >
                          {each.description}
                        </ParagraphTypography>
                      </Collapse>
                    </Box>
                    <Hidden smDown>
                      <Divider light />
                    </Hidden>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
          <Box textAlign="center">
            <img
              alt="Google contacts CRM integration, Outlook contacts CRM integration"
              className={classes.img}
              maxWidth="800px"
              src={buildContactFeatureImg}
            />
            <Box py={{ sm: 6, xs: 4 }[width] || 10}>
              <Button
                className={classes.bookNowButton}
                color="secondary"
                component={Link}
                size="large"
                to="/contact"
                variant="contained"
              >
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                Let's Integrate
              </Button>
            </Box>
          </Box>
        </Box>
        <Box pt={4}>
          <Grid
            alignItems="center"
            container
            direction={{ sm: "column", xs: "column" }[width]}
            justify="center"
          >
            <Grid item md={6} sm={12}>
              <Box
                display="flex"
                justifyContent={{ sm: "center", md: "flex-end" }}
              >
                <Box maxWidth={620} p={1}>
                  <AccordionHeader
                    alt="Software Integration"
                    description="Streamline your business, close deals faster, and manage your accounts by integrating Pepper Cloud CRM with your favourite accounting software."
                    header="h3"
                    img={softwareIntegration}
                    title="Run business efficiently with accounting software integration"
                  />
                  <Box mb={2} mt={3}>
                    <Divider light />
                  </Box>
                  {integration.map((each, index) => (
                    <div key={each.title}>
                      <Box my={1} p={2}>
                        <Box
                          alignItems="center"
                          display="flex"
                          justifyContent="space-between"
                          onClick={() =>
                            setActiveCalendar(
                              index === activeCalendar ? -1 : index
                            )
                          }
                        >
                          <Box
                            component="h4"
                            fontSize="h6.fontSize"
                            fontWeight="600"
                            m={0}
                          >
                            {each.title}
                          </Box>
                          <IconButton
                            aria-label="Expand or Collapse"
                            className={classes.expendButton}
                            color="default"
                          >
                            {index !== activeCalendar ? (
                              <AddIcon fontSize="small" />
                            ) : (
                              <RemoveIcon fontSize="small" />
                            )}
                          </IconButton>
                        </Box>
                        <Collapse in={activeCalendar === index}>
                          <ParagraphTypography fontSize={16} mr={6} mt={2}>
                            {each.description}
                          </ParagraphTypography>
                        </Collapse>
                      </Box>
                      <Divider light />
                    </div>
                  ))}
                  <Box pt={5} textAlign="center">
                    <Button
                      className={classes.bookNowButton}
                      color="secondary"
                      component={Link}
                      size="large"
                      to="/xero"
                      variant="contained"
                    >
                      {/* eslint-disable-next-line react/no-unescaped-entities */}
                      Read More
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item md={1} sm={12}></Grid>
            <Grid item md={5} sm={12}>
              <img
                alt="CRM system integration with accounting software, xero crm integrations, quickbooks crm integration"
                className={classes.img}
                src={softwareIntegrationFeatureImg}
              />
            </Grid>
          </Grid>
          <Hidden smDown>
            <Box mt={8}>
              <Divider light />
            </Box>
          </Hidden>
        </Box>
        <Box pt={4} className={classes.videoConference}>
          <Grid
            alignItems="center"
            container
            direction={{ sm: "column-reverse", xs: "column-reverse" }[width]}
            justify="center"
          >
            <Grid item md={5} sm={12} className={classes.imageBigscreenRight}>
              <img
                alt="Zoom integrated CRM, video conferencing CRM"
                className={classes.img}
                src={integrationConferencing}
              />
            </Grid>
            <Grid item md={1} sm={12}></Grid>
            <Grid item md={6} sm={12}>
              <Box
                display="flex"
                justifyContent={{ sm: "center", md: "flex-start" }}
              >
                <Box maxWidth={620} p={1}>
                  <AccordionHeader
                    alt="Vedio Conferencing"
                    description="Discover remote selling and scale up your sales communication by integrating Pepper Cloud CRM with the world-class video conferencing tool, Zoom!"
                    header="h3"
                    img={conferencingIcon}
                    title="Boost your sales velocity with video conferencing"
                  />
                  <Box mb={2} mt={3}>
                    <Divider light />
                  </Box>
                  {videoConferencingChannel.map((each, index) => (
                    <div key={each.title}>
                      <Box my={1} p={2}>
                        <Box
                          alignItems="center"
                          display="flex"
                          justifyContent="space-between"
                          onClick={() =>
                            setActiveCalendar(
                              index === activeCalendar ? -1 : index
                            )
                          }
                        >
                          <Box
                            component="h4"
                            fontSize="h6.fontSize"
                            fontWeight="600"
                            m={0}
                          >
                            {each.title}
                          </Box>
                          <IconButton
                            aria-label="Expand or Collapse"
                            className={classes.expendButton}
                            color="default"
                          >
                            {index !== activeCalendar ? (
                              <AddIcon fontSize="small" />
                            ) : (
                              <RemoveIcon fontSize="small" />
                            )}
                          </IconButton>
                        </Box>
                        <Collapse in={activeCalendar === index}>
                          <ParagraphTypography fontSize={16} mr={6} mt={2}>
                            {each.description}
                          </ParagraphTypography>
                        </Collapse>
                      </Box>
                      <Divider light />
                    </div>
                  ))}
                  <Box py={8} textAlign="center">
                    <Button
                      className={classes.bookNowButton}
                      color="secondary"
                      component={Link}
                      size="large"
                      to="/zoom"
                      variant="contained"
                    >
                      {/* eslint-disable-next-line react/no-unescaped-entities */}
                      Read More
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Hidden smDown>
            <Box>
              <Divider light />
            </Box>
          </Hidden>
        </Box>
        <Box pt={4}>
          <Grid
            alignItems="center"
            container
            direction={{ sm: "column", xs: "column" }[width]}
            justify="center"
          >
            <Grid item md={6} sm={12}>
              <Box
                display="flex"
                justifyContent={{ sm: "center", md: "flex-end" }}
              >
                <Box maxWidth={620} p={1}>
                  <AccordionHeader
                    alt="Integration Lead Capture"
                    description="Stop spending time searching for leads across multiple platforms and start capturing data automatically."
                    header="h3"
                    textAlign={{ sm: "center" }}
                    // img={integrationLeadCaptureImg}
                    title="Capture leads effortlessly and capitalise on your deals"
                  />
                  <Box mb={2} mt={3}>
                    <Divider light />
                  </Box>
                  {captureLeadByChannel.map((each, index) => (
                    <div key={each.title}>
                      <Box my={1} p={2}>
                        <Box
                          alignItems="center"
                          display="flex"
                          justifyContent="space-between"
                          onClick={() =>
                            setActiveCalendar(
                              index === activeCalendar ? -1 : index
                            )
                          }
                        >
                          <Box
                            component="h4"
                            fontSize="h6.fontSize"
                            fontWeight="600"
                            m={0}
                          >
                            {each.title}
                          </Box>
                          <IconButton
                            aria-label="Expand or Collapse"
                            className={classes.expendButton}
                            color="default"
                          >
                            {index !== activeCalendar ? (
                              <AddIcon fontSize="small" />
                            ) : (
                              <RemoveIcon fontSize="small" />
                            )}
                          </IconButton>
                        </Box>
                        <Collapse in={activeCalendar === index}>
                          <ParagraphTypography fontSize={16} mr={6} mt={2}>
                            {each.description}
                          </ParagraphTypography>
                        </Collapse>
                      </Box>
                      <Divider light />
                    </div>
                  ))}
                </Box>
              </Box>
            </Grid>
            <Grid item md={1} sm={12}></Grid>
            <Grid item md={5} sm={12}>
              <img
                alt="Zapier intergated CRM, crm shopify integration"
                className={classes.img}
                src={integrationLeadCaptureImg}
              />
            </Grid>
          </Grid>
          <Hidden smDown>
            <Box mt={8}>
              <Divider light />
            </Box>
          </Hidden>
        </Box>
        <Box
          className={classes.integrationsFeatures}
          py={{ sm: 6, xs: 4 }[width] || 12}
        >
          <Container>
            <Grid alignItems="center" container justify="center" spacing={2}>
              <Grid item md={5} sm={12}>
                <HeaderTypography
                  color="common.white"
                  component="h3"
                  fontSize={36}
                  fontWeight={600}
                  mb={4}
                >
                  Expect more from CRM integrations
                </HeaderTypography>
                <ParagraphTypography
                  color="common.white"
                  fontSize={16}
                  mr={6}
                  mt={2}
                  textAlign="initial"
                >
                  Build seamless workflows and better customer relationships
                  with Pepper Cloud CRM.
                </ParagraphTypography>
              </Grid>
              <Grid item md={1} sm={0}></Grid>
              <Grid item md={6} sm={12}>
                <Box>
                  <Grid container spacing={4}>
                    <Grid item md={6} sm={6} xs={12}>
                      <div className={classes.integrationFeatureItem}>
                        <Box
                          alt="increase sales conversion rate with CRM integrations"
                          component="img"
                          mt={2}
                          src={integrationFeatureEngage}
                          width={50}
                        />
                        <HeaderTypography
                          component="h4"
                          fontSize={18}
                          fontWeight={600}
                          mt={1}
                          my={1.5}
                          variant="h5"
                        >
                          Engage
                        </HeaderTypography>
                        <ParagraphTypography
                          color="text.paragraph"
                          fontSize={16}
                          m={0}
                        >
                          Have powerful conversations with your prospects, grow
                          your{" "}
                          <a href="https://peppercloud.com/sales-pipeline-management-tool">
                            sales pipeline
                          </a>
                          , and convert your leads into opportunities.
                        </ParagraphTypography>
                      </div>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12}>
                      <div className={classes.integrationFeatureItem}>
                        <Box
                          alt="Send personalized lead nurturing campaign with CRM"
                          component="img"
                          mt={2}
                          src={integrationFeatureNuture}
                          width={50}
                        />
                        <HeaderTypography
                          component="h4"
                          fontSize={18}
                          fontWeight={600}
                          mt={1}
                          my={1.5}
                        >
                          Nurture
                        </HeaderTypography>
                        <ParagraphTypography
                          color="text.paragraph"
                          fontSize={16}
                          m={0}
                        >
                          Build personalised relationships with your customers
                          by sending out the information they would like to
                          read.
                        </ParagraphTypography>
                      </div>
                    </Grid>
                  </Grid>
                  <br />
                  <br />
                  <Grid container spacing={4}>
                    <Grid item md={6} sm={6} xs={12}>
                      <div className={classes.integrationFeatureItem}>
                        <Box
                          alt="sales conversions with integrated CRM"
                          component="img"
                          mt={2}
                          src={integrationFeatureConversion}
                          width={50}
                        />
                        <HeaderTypography
                          component="h4"
                          fontSize={18}
                          fontWeight={600}
                          mt={1}
                          my={1.5}
                        >
                          Convert
                        </HeaderTypography>
                        <ParagraphTypography
                          color="text.paragraph"
                          fontSize={16}
                          m={0}
                        >
                          Increase the conversion rates and winning
                          opportunities with the help of various{" "}
                          <a
                            href={
                              "https://blog.peppercloud.com/sales-activities-building-blocks-of-sales-process/"
                            }
                          >
                            sales activities
                          </a>
                          .
                        </ParagraphTypography>
                      </div>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12}>
                      <div className={classes.integrationFeatureItem}>
                        <Box
                          alt="Sales growth with fully integrated CRM"
                          component="img"
                          mt={2}
                          src={integrationFeatureGrow}
                          width={50}
                        />
                        <HeaderTypography
                          component="h4"
                          fontSize={18}
                          fontWeight={600}
                          mt={1}
                          my={1.5}
                        >
                          Grow
                        </HeaderTypography>
                        <ParagraphTypography
                          color="text.paragraph"
                          fontSize={16}
                          m={0}
                        >
                          Integrate CRM account with a collection of
                          sales-boosting apps to sell more with fewer efforts.
                        </ParagraphTypography>
                      </div>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Box className={classes.lightBlueBg}>
          <Container>
            <Box display="flex" justifyContent="center">
              <Box
                maxWidth={1015}
                py={{ xs: 4, sm: 6, md: 10 }}
                textAlign="center"
              >
                <HeaderTypography component="h3" fontSize={40} mb={2} mt={0}>
                  APIs for extensive CRM system integrations
                </HeaderTypography>
                <ParagraphTypography color="text.paragraph" fontSize={18} m={0}>
                  Contact us and we will help you to integrate your favourite
                  apps. With our CRM API integration, you can integrate the
                  accounting and ERP software, online commerce, social media
                  channels, project management tools and many more.
                </ParagraphTypography>
                <Box mt={4}>
                  <Button
                    className={classes.bookNowButton}
                    color="secondary"
                    component={Link}
                    size="large"
                    to="/contact"
                    variant="contained"
                  >
                    Get Started
                  </Button>
                </Box>
              </Box>
            </Box>
          </Container>
        </Box>

        <Box component={Container} mb={4} textAlign="center">
          <Box py={8}>
            <HeaderTypography
              alignItems="center"
              component="h3"
              display="flex"
              fontSize={44}
              justifyContent="center"
              lineHeight={1.2}
              mb={2}
              mt={0}
            >
              Our customers are automating workflows with our easy integrations
            </HeaderTypography>
            <ParagraphTypography>
              Many businesses trust Pepper Cloud CRM software to run their
              operations globally. Know about our clients’ experiences in their
              words.
            </ParagraphTypography>
          </Box>
          <Container>
            <SingleTestimonial {...client} />
          </Container>
        </Box>

        <Box
          className={classes.requestDemoRoot}
          px={2}
          py={{ sm: 10, xs: 8 }[width] || 12}
        >
          <HeaderTypography
            color="common.white"
            component="h2"
            fontSize={24}
            mt={0}
            textAlign="center"
          >
            Are you ready to scale up your business and power up your sales?
          </HeaderTypography>

          <Box mt={6} textAlign="center">
            <WhiteButton
              color="primary"
              component={Link}
              size="large"
              to="/contact"
              variant="contained"
            >
              {`Let's Do This!`}
            </WhiteButton>
          </Box>
        </Box>
      </div>
    </React.Fragment>
  );
};

export default Integration;
